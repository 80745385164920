import { createSlice } from "@reduxjs/toolkit";
import { getAveragePayInOutTime, getDashboardPayInOutStat } from "./actions";

const initialState = {
  payInOutStat: {
    payInData: [],
    payOutData: [],
    manualOrder: [],
  },

  averageTime: {
    payinAverageTime: null,
    payoutAverageTime: null,
  },
};

const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardPayInOutStat.fulfilled, (state, action) => {
      return {
        ...state,
        payInData: action.payload?.payInData,
        payOutData: action.payload?.payOutData,
        manualOrder: action.payload?.manualOrder || [],
      };
    });

    builder.addCase(getAveragePayInOutTime.fulfilled, (state, action) => {
      return {
        ...state,
        averageTime: {
          ...state.averageTime,
          payinAverageTime: action.payload?.payinAverageTimeData || null,
          payoutAverageTime: action.payload?.payoutAverageTimeData || null,
        },
      };
    });
  },
});

export default dashboardSlice.reducer;
