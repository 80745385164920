export const ENUM_DEPOSIT_TYPE = {
  1: "MANUAL",
  2: "ONLINE",
};

export const ENUM_ROLE_TYPE = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  VENDOR: "VENDOR",
  SUBADMIN: "SUBADMIN",
};

export const ENUM_DEPOSIT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  APPROVED_WITH_PRICE_CHANGE: 2,
  DECLINED: 3,
  FAILED: 4,
};

export const ENUM_WITHDRAW_STATUS = {
  PENDING: 0,
  VERIFIED: 1,
  APPROVED: 2,
  DECLINED: 3,
  CANCELLED: 5,
};

export const ENUM_WITHDRAW_DEPOSIT = {
  DEPOSIT: 1,
  WITHDRAW: 2,
};

export const ENUM_GENERAL_SETTING = {
  ONLINE_PAYMENT: "onlinePayment",
  SOCIAL_MEDIA: "socialMedia",
  MARKUP_TEXT: "markupText",
  USER_TYPE: "userType",
};

export const DATE_RANGE = {
  today: "Today",
  yesterday: "Yesterday",
  last7Days: "Last 7 Days",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  customDate: "Custom Date",
};

export const LAST_LOGGEDIN_RANGE = {
  last7: "Last 7 Days",
  last8To15: "8-15 Days",
  last16To30: "16-30 Days",
  greaterThan30: "Greater than 30 Days",
};

export const ENUM_PAYMENT_TYPE = {
  MANUAL: 1,
  ONLINE: 2,
};

export const PAYOUT_MODE = {
  UPI: "UPI",
  BANK: "BANK",
};

export const dropdownDateMapping = {
  [DATE_RANGE.today]: (() => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );

    return { startDate, endDate };
  })(),
  [DATE_RANGE.yesterday]: (() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const startDate = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate());
    const endDate = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [DATE_RANGE.last7Days]: (() => {
    const today = new Date();
    const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startDate = new Date(last7Days.getFullYear(), last7Days.getMonth(), last7Days.getDate());
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [DATE_RANGE.thisMonth]: (() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const startDate = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      firstDayOfMonth.getDate()
    );
    const endDate = new Date(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [DATE_RANGE.lastMonth]: (() => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1);
    const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
    const firstDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
    const startDate = new Date(
      firstDayOfLastMonth.getFullYear(),
      firstDayOfLastMonth.getMonth(),
      firstDayOfLastMonth.getDate()
    );
    const endDate = new Date(
      lastDayOfLastMonth.getFullYear(),
      lastDayOfLastMonth.getMonth(),
      lastDayOfLastMonth.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),

  [DATE_RANGE.customDate]: (start, end) => {
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999);

    return { startDate, endDate };
  },
};

export const getStartEndDayDate = (startDate, endDate) => {
  let sDate = null;
  let eDate = null;
  if (startDate) {
    sDate = new Date(startDate);
    sDate = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), 0, 0, 0, 0);
  }
  if (endDate) {
    eDate = new Date(endDate);
    eDate = new Date(eDate.getFullYear(), eDate.getMonth(), eDate.getDate(), 23, 59, 59, 999);
  }

  return { sDate, eDate };
};

export const TRANSACTION_TYPE = {
  DEPOSIT: 1,
  WITHDRAW: 2,
  WIN: 3,
  LOSE: 4,
  MANUAL_DEPOSIT: 5,
  MANUAL_WITHDRAW: 6,
  REFUND: 7,
  CASINO_DEPOSIT: 11,
  CASINO_WITHDRAW: 12,
  CASINO_ROLLBACK: 13,
  ABUNDANT: 14,
};

export const TRANSACTION_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  IN_PROGRESS: 2,
  DECLINED: 3,
  FAILED: 4,
};

export const BET_RESULT = {
  OPEN: "OPEN",
  WIN: "WIN",
  LOSE: "LOSE",
  REFUND: "REFUND",
  ABUNDANT: "ABUNDANT",
};

export const loggedInDateMapping = {
  [LAST_LOGGEDIN_RANGE.last7]: (() => {
    const today = new Date();
    const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startDate = new Date(last7Days.getFullYear(), last7Days.getMonth(), last7Days.getDate());
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [LAST_LOGGEDIN_RANGE.last8To15]: (() => {
    const today = new Date();
    const last15Days = new Date(today.getTime() - 15 * 24 * 60 * 60 * 1000);
    const startDate = new Date(
      last15Days.getFullYear(),
      last15Days.getMonth(),
      last15Days.getDate()
    );
    const last8Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const endDate = new Date(
      last8Days.getFullYear(),
      last8Days.getMonth(),
      last8Days.getDate(),
      23,
      59,
      59,
      999
    );

    return { startDate, endDate };
  })(),
  [LAST_LOGGEDIN_RANGE.last16To30]: (() => {
    const today = new Date();
    const last30Days = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const startDate = new Date(
      last30Days.getFullYear(),
      last30Days.getMonth(),
      last30Days.getDate()
    );
    const last16Days = new Date(today.getTime() - 16 * 24 * 60 * 60 * 1000);
    const endDate = new Date(
      last16Days.getFullYear(),
      last16Days.getMonth(),
      last16Days.getDate(),
      23,
      59,
      59,
      999
    );

    return { startDate, endDate };
  })(),
  [LAST_LOGGEDIN_RANGE.greaterThan30]: (() => {
    const today = new Date();
    const last30Days = new Date(today.getTime() - 31 * 24 * 60 * 60 * 1000);
    const startDate = new Date(
      last30Days.getFullYear(),
      last30Days.getMonth(),
      last30Days.getDate()
    );

    return { startDate };
  })(),
};

export const ENUM_USER_TYPE = {
  //Ensure the elements within ENUM_USER_TYPE remain in their current sequence
  1: "BRONZE",
  2: "SILVER",
  3: "GOLD",
  4: "DIAMOND",
  5: "PLATINUM",
};

export const MODULES = [
  { name: "Pay-in", id: "pay-in", parentId: "payment" },
  { name: "Pay-out", id: "pay-out", parentId: "payment" },
  { name: "Manual-list", id: "manual-list" },
  { name: "unmatch-manual-list", id: "unmatch-manual-list" },
  { name: "User-Banks", id: "user-bank-list", parentId: "banks" },
];

export const ORDER_TYPE = {
  PAYIN: "PAYIN",
  PAYOUT: "PAYOUT",
};

export const PAYMENT_MODE = {
  MANUAL: "MANUAL",
  ONLINE: "ONLINE",
};

export const ENUM_USER_BY_PAYMENT_TYPE = {
  MANUAL: "MANUAL",
  ONLINE: "ONLINE",
};
