import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../helpers/api_helper";
import { getManualOrderCount, getPayInOutCount } from "../store/actions";

const Navdata = () => {
  const history = useNavigate();
  const { paymentCount, manualOrderCount, userProfile } = useSelector((state) => ({
    paymentCount: state.Payments.paymentCount,
    manualOrderCount: state.ManualOrder.manualOrderCount,
    userProfile: state.User.user,
  }));

  const dispatch = useDispatch();
  const isLoggedIn = getLoggedinUser();
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getPayInOutCount());
      dispatch(getManualOrderCount());
    }
  }, []);
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isManualOrder, setIsManualOrder] = useState(false);
  const [isAdministration, setIsAdministration] = useState(false);
  const [isSport, setIsSport] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isBank, setIsBank] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id)) document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") setIsDashboard(false);
    if (iscurrentState !== "User") setIsUser(false);
    if (iscurrentState !== "ManualOrder") setIsManualOrder(false);
    if (iscurrentState !== "Sport") setIsSport(false);
    if (iscurrentState !== "Setting") setIsSetting(false);
    if (iscurrentState !== "Bank") setIsBank(false);
    if (iscurrentState !== "Payment") setIsPayment(false);
    if (iscurrentState !== "Administration") setIsAdministration(false);
    if (iscurrentState !== "Reports") setIsReports(false);

    // if (iscurrentState === "Widgets") {
    //   history("/widgets");
    //   document.body.classList.add("twocolumn-panel");
    // }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isUser,
    isManualOrder,
    isSport,
    isBank,
    isAdministration,
    isPayment,
    isReports,
  ]);
  const totalCount = (paymentCount?.payInCount || 0) + (paymentCount?.payOutCount || 0);
  let menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      id: "users",
      label: "Users",
      icon: "ri-user-2-line",
      link: "/users",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("User");
      },
    },
    {
      id: "manual-order",
      label: `Manual Order${manualOrderCount ? "(" + manualOrderCount + ")" : ""}`,
      icon: "ri-luggage-cart-line",
      stateVariables: isManualOrder,
      click: function (e) {
        e.preventDefault();
        setIscurrentState("ManualOrder");
        setIsManualOrder(!isManualOrder);
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "manual-list",
          label: `Manual Order${manualOrderCount ? "(" + manualOrderCount + ")" : ""}`,
          link: "/manual-order",
          parentId: "manual-order",
        },
        {
          id: "unmatch-manual-list",
          label: `Unmatch Manual Order`,
          link: "/unmatch-manual-order",
          parentId: "manual-order",
        },
      ],
    },
    {
      id: "banks",
      label: "Banks",
      icon: "ri-bank-line",
      link: "/banks",
      stateVariables: isBank,
      click: function (e) {
        e.preventDefault();
        setIsBank(!isBank);
        setIscurrentState("Bank");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "bank-list",
          label: "Banks",
          link: "/banks/all-banks",
          parentId: "banks",
        },
        // {
        //   id: "user-bank-list",
        //   label: "User Banks",
        //   link: "/banks/user-banks",
        //   parentId: "banks",
        // },
      ],
    },
    {
      id: "payment",
      label: `Payment ${totalCount ? "(" + totalCount + ")" : ""}`,
      icon: "ri-money-dollar-circle-line",
      link: "/payments",
      stateVariables: isPayment,
      click: function (e) {
        e.preventDefault();
        setIsPayment(!isPayment);
        setIscurrentState("Payment");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pay-in",
          label: `Pay-in ${paymentCount?.payInCount ? "(" + paymentCount?.payInCount + ")" : ""}`,
          link: "/payments/pay-in",
          parentId: "payment",
        },
        {
          id: "pay-out",
          label: `Pay-out ${
            paymentCount?.payOutCount ? "(" + paymentCount?.payOutCount + ")" : ""
          }`,
          link: "/payments/pay-out",
          parentId: "payment",
        },
      ],
    },
    {
      id: "reports",
      label: "Reports",
      icon: "ri-pages-line",
      link: "/reports",
      stateVariables: isReports,
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pay-in-out-report",
          label: "Pay in-out Report",
          link: "/reports/pay-in-out-report",
          parentId: "reports",
        },
        {
          id: "pay-in-history",
          label: "Pay-in History",
          link: "/reports/pay-in-history",
          parentId: "reports",
        },
        {
          id: "pay-out-history",
          label: "Pay-out History",
          link: "/reports/pay-out-history",
          parentId: "reports",
        },
        {
          id: "manual-order-history",
          label: "Manual Order History",
          link: "/reports/manual-order-history",
          parentId: "reports",
        },
        {
          id: "transaction-history",
          label: "Transaction History",
          link: "/reports/transaction-history",
          parentId: "reports",
        },
      ],
    },
    {
      id: "administration",
      label: "Administration",
      icon: "ri-shield-line",
      link: "/administration",
      stateVariables: isAdministration,
      click: function (e) {
        e.preventDefault();
        setIsAdministration(!isAdministration);
        setIscurrentState("Administration");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "admin",
          label: "Admin",
          link: "/administration/admin",
          parentId: "administration",
        },
      ],
    },
    {
      id: "settings",
      label: "Settings",
      icon: "ri-settings-3-line",
      link: "/settings",
      stateVariables: isSetting,
      click: function (e) {
        e.preventDefault();
        setIsSetting(!isSetting);
        setIscurrentState("Setting");
        updateIconSidebar(e);
      },
      subItems: [
        // {
        //   id: "payout-merchant",
        //   label: "Payout Merchant",
        //   link: "/settings/payout-merchant",
        //   parentId: "settings",
        // },
        {
          id: "general-setting",
          label: "General Setting",
          link: "/settings/general-setting",
          parentId: "settings",
        },
      ],
    },
  ];

  // if (userProfile.type == ENUM_ADMIN_TYPE.SUPERADMIN) {
  //   menuItems = menuItems.filter((ele) => ele.id != "administration");
  // }

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
