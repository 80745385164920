import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createUserBanks,
  deleteUserBanks,
  getUserBanks,
  getUsersAssignedToBank,
  patchUserBanks,
} from "./actions";

const initialState = {
  userBank: { data: [], totalRows: 0 },
  assignedUsers: [],
};

const userBankSlice = createSlice({
  name: "UserBank",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getUserBanks.fulfilled, (state, action) => {
      state.userBank.data = action.payload.items;
      state.userBank.totalRows = action.payload.totalRows;
    });
    builder.addCase(getUsersAssignedToBank.fulfilled, (state, action) => {
      state.assignedUsers = action.payload;
    });

    builder.addCase(createUserBanks.fulfilled, (state, action) => {
      if (action.payload?.status)
        toast.success("User Bank assigned successfully.");
    });

    builder.addCase(patchUserBanks.fulfilled, (state, action) => {
      if (action.payload?.status)
        toast.success("User Bank status updated successfully.");
    });

    builder.addCase(deleteUserBanks.fulfilled, (state, action) => {
      if (action.payload?.status)
        toast.success("User Bank deleted successfully.");
    });
  },
});

export default userBankSlice.reducer;
