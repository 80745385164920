import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPayInHistoryAPI,
  getPayInOutReportAPI,
  getPayOutHistoryAPI,
  getManualOrderHistoryAPI,
  downloadPayInOutReportApi,
  downloadPayInReportApi,
  downloadPayOutReportApi,
  downloadMaualOrderReportApi,
  getTransactionHistoryAPI,
  downloadTransactionHistoryReportApi,
} from "../../helpers/apis";

export const getPayInOutReport = createAsyncThunk("Reports/getPayInOutReport", async (payload) => {
  const response = await getPayInOutReportAPI(payload);
  return response.data;
});

export const getPayOutHistory = createAsyncThunk("Reports/getPayOutHistory", async (payload) => {
  const response = await getPayOutHistoryAPI(payload);
  return response.data;
});

export const getPayInHistory = createAsyncThunk("Reports/getPayInHistory", async (payload) => {
  const response = await getPayInHistoryAPI(payload);
  return response.data;
});

export const getManualOrderHistory = createAsyncThunk(
  "Reports/getManualOrderHistory",
  async (payload) => {
    const response = await getManualOrderHistoryAPI(payload);
    return response.data;
  }
);

export const getTransactionHistory = createAsyncThunk(
  "Reports/getTransactionHistory",
  async (payload) => {
    const response = await getTransactionHistoryAPI(payload);
    return response.data;
  }
);

export const downloadPayInOutReport = createAsyncThunk(
  "Report/downloadDepositCSV",
  async (payload) => {
    const response = await downloadPayInOutReportApi(payload);
    return response;
  }
);

export const downloadPayInReport = createAsyncThunk(
  "Report/downloadPayInReport",
  async (payload) => {
    const response = await downloadPayInReportApi(payload);
    return response;
  }
);

export const downloadPayOutReport = createAsyncThunk(
  "Report/downloadPayOutReport",
  async (payload) => {
    const response = await downloadPayOutReportApi(payload);
    return response;
  }
);

export const downloadManualOrderReport = createAsyncThunk(
  "Report/downloadManualOrderReport",
  async (payload) => {
    const response = await downloadMaualOrderReportApi(payload);
    return response;
  }
);


export const downloadTransactionHistoryReport = createAsyncThunk(
  "Report/downloadTransactionHistoryReport",
  async (payload) => {
    const response = await downloadTransactionHistoryReportApi(payload);
    return response;
  }
);
