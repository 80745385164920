import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import User from "./user/reducer";
import Users from "./users/reducer";
import UserBank from "./userBank/reducer";
import Banks from "./banks/reducer";
import Payments from "./payments/reducer";
import Admins from "./administration/admins/reducer";
import Reports from "./reports/reducer";
import Dashboard from "./dashboard/reducer";
import Settings from "./settings/reducer";
import ManualOrder from "./manualOrder/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  User,
  Users,
  UserBank,
  Banks,
  Payments,
  Admins,
  Reports,
  Dashboard,
  Settings,
  ManualOrder,
});

export default rootReducer;
