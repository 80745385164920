import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  assignMerchantToUsersAPI,
  createMerchantsAPI,
  deleteMerchantsAPI,
  deleteMerchantToUsersAPI,
  getGeneralSettingsApi,
  getMerchantListAPI,
  getMerchantsAPI,
  getMerchantUsersAPI,
  updateGeneralSettingsApi,
  updateMerchantsAPI,
} from "../../helpers/apis";

export const getMerchants = createAsyncThunk(
  "settings/getMerchants",
  async (payload) => {
    const response = await getMerchantsAPI(payload);
    return response.data;
  }
);

export const getMerchantList = createAsyncThunk(
  "settings/getMerchantList",
  async (payload) => {
    const response = await getMerchantListAPI(payload);
    return response.data;
  }
);

export const getMerchantUsers = createAsyncThunk(
  "user/getMerchantUsers",
  async (payload) => {
    const response = await getMerchantUsersAPI(payload);
    return response.data;
  }
);

export const assignMerchantToUsers = createAsyncThunk(
  "user/assignMerchantToUsers",
  async (payload) => {
    const response = await assignMerchantToUsersAPI(payload);
    return response;
  }
);

export const deleteMerchantUsers = createAsyncThunk(
  "user/deleteMerchantUsers",
  async (payload) => {
    const response = await deleteMerchantToUsersAPI(
      payload.merchantId,
      payload.userIds
    );
    return response;
  }
);

export const createMerchant = createAsyncThunk(
  "settings/createMerchant",
  async (payload) => {
    const response = await createMerchantsAPI(payload);
    return response.data;
  }
);

export const updateMerchant = createAsyncThunk(
  "settings/updateMerchant",
  async (payload) => {
    const response = await updateMerchantsAPI(payload);
    return response.data;
  }
);

export const deleteMerchant = createAsyncThunk(
  "settings/deleteMerchant",
  async (payload) => {
    const response = await deleteMerchantsAPI(payload);
    return response.data;
  }
);

export const getGeneralSettings = createAsyncThunk(
  "settings/getGeneralSettings",
  async (data) => {
    const response = await getGeneralSettingsApi(data);
    return response.data;
  }
);

export const updateGeneralSettings = createAsyncThunk(
  "settings/updateGeneralSettings",
  async (data) => {
    const response = await updateGeneralSettingsApi(data);
    return response.data;
  }
);
