import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUsers as getUsersAPI,
  getUserList as getUserListAPI,
  addUser as addUserAPI,
  getProfile as getProfileAPI,
  fetchSecurityKey,
  fetchToken,
  updateUserApi,
  fetchUserBalance,
} from "../../helpers/apis";
import { toast } from "react-toastify";

export const updateUserDetail = createAsyncThunk(
  "Users/updateUserDetail",
  async (data) => {
    try {
      if (!("update" in data)) return;
      const res = await updateUserApi(data.update);
      delete data.update;
      toast.success("User Update Successfully", { autoClose: 3000 });
      return res.data;
    } catch (error) {
      toast.error("Failed to Update user", { autoClose: 3000 });
      return false;
    }
  }
);

export const getUsers = createAsyncThunk("user/getUsers", async (params) => {
  const response = await getUsersAPI(params);
  return response.data;
});

export const getUserList = createAsyncThunk(
  "user/getUserList",
  async (params) => {
    const response = await getUserListAPI(params);
    return response.data;
  }
);

export const addUser = createAsyncThunk("user/addUser", async (payload) => {
  const response = await addUserAPI(payload);
  return response.data;
});

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (payload) => {
    const response = await getProfileAPI(payload);
    return response.data;
  }
);

export const getSecurityKey = createAsyncThunk(
  "user/getSecurityKey",
  async (payload) => {
    const response = await fetchSecurityKey(payload);
    return response.data;
  }
);

export const getUserBalance = createAsyncThunk(
  "user/userBalance",
  async (payload) => {
    const response = await fetchUserBalance(payload);
    return response.data;
  }
);


export const getToken = createAsyncThunk("user/getToken", async (payload) => {
  const response = await fetchToken(payload);
  return response.data;
});
