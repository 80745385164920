import { createSlice } from "@reduxjs/toolkit";

import { deletePaymentBank, getBankList, getPaymentBankList, updatePaymentBank } from "./actions";
import { toast } from "react-toastify";

const initialState = {
  banks: [],
  paymentBanks: { data: [], total: 0 },
};

const bankSlice = createSlice({
  name: "Banks",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getBankList.fulfilled, (state, action) => {
        state.banks = action.payload;
      })
      .addCase(getBankList.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to fetch, Please try again"
        );
      })
      .addCase(getPaymentBankList.fulfilled, (state, action) => {
        state.paymentBanks.data = action.payload.items;
        state.paymentBanks.total = action.payload.total;
      })
      .addCase(deletePaymentBank.fulfilled, (state, action) => {
        toast.success("Bank deleted")
      })
      .addCase(deletePaymentBank.rejected, (state, action) => {
        toast.error(action?.payload?.message || action.error?.message || "Something went wrong, Please try again")
      })
      .addCase(updatePaymentBank.fulfilled, (state, action) => {
        toast.success("Bank updated")
      })
      .addCase(updatePaymentBank.rejected, (state, action) => {
        toast.error(action?.payload?.message || action.error?.message || "Something went wrong, Please try again")
      })
  },
});

export default bankSlice.reducer;
