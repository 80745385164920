import { createAsyncThunk } from "@reduxjs/toolkit";

export const changeLoader = createAsyncThunk("Layout/changeLoader", (value) => {
  return value;
});

export const changeLayoutMode = createAsyncThunk(
  "Layout/changeLayoutMode",
  (mode) => {
    try {
      changeHTMLAttribute("data-layout-mode", mode);
      localStorage.setItem("layoutModeType", mode);
      return mode;
    } catch (error) {
      // console.log(error);
    }
  }
);

export const resetValue = (value) => ({});

function changeHTMLAttribute(attribute, value) {
  if (document.documentElement)
    document.documentElement.setAttribute(attribute, value);
  return true;
}
