import { createSlice } from "@reduxjs/toolkit";

import {
  getPayInOutCount,
  getPayInRequests,
  getPayOutRequests,
  getUserList,
  getUsers,
  processPayInRequest,
} from "./actions";
import { toast } from "react-toastify";
import { getManualOrderCount } from "../actions";

const initialState = {
  payInRequests: [],
  totalRows: 0,
  payOutRequests: [],
  totalPayoutRows: 0,
  paymentCount: {},
};

const paymentsSlice = createSlice({
  name: "Payments",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getPayInRequests.fulfilled, (state, action) => {
        state.payInRequests = action.payload.items;
        state.totalRows = action.payload.totalRows;
      })
      .addCase(getPayInRequests.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to fetch, Please try again"
        );
      })
      .addCase(getPayOutRequests.fulfilled, (state, action) => {
        state.payOutRequests = action.payload.items;
        state.totalPayoutRows = action.payload.totalRows;
      })
      .addCase(getPayOutRequests.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to fetch, Please try again"
        );
      })
      .addCase(getPayInOutCount.fulfilled, (state, action) => {
        state.paymentCount = action.payload;
      })
      .addCase(processPayInRequest.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Something went wrong, Please try again"
        );
      });
  },
});

export default paymentsSlice.reducer;
