import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getManualOrderAPI,
  getUnmatchManualOrdersAPI,
  processManualOrderRequestAPI,
  processImageRequestAPI,
  declineAllManualOrderRequestAPI,
  getManualOrderCountAPI,
} from "../../helpers/apis";

export const getManualOrders = createAsyncThunk("manualOrder/getManualOrders", async (params) => {
  const response = await getManualOrderAPI(params);
  return response.data;
});

export const getUnmatchManualOrders = createAsyncThunk(
  "manualOrder/getUnmatchManualOrders",
  async (params) => {
    const response = await getUnmatchManualOrdersAPI(params);
    return response.data;
  }
);

export const getManualOrderCount = createAsyncThunk(
  "manualOrder/getManualOrderCount",
  async (payload) => {
    const response = await getManualOrderCountAPI(payload);
    return response.data;
  }
);

export const processManualOrderRequest = createAsyncThunk(
  "manualOrder/processManualOrderRequest",
  async (payload) => {
    const response = await processManualOrderRequestAPI(payload);
    return response.data;
  }
);

export const declineAllManualOrderRequests = createAsyncThunk(
  "manualOrder/declineAllManualOrderRequests",
  async (payload) => {
    const response = await declineAllManualOrderRequestAPI(payload);
    return response.data;
  }
);

export const processImageRequest = createAsyncThunk(
  "manualOrder/processImageRequest",
  async (payload) => {
    const response = await processImageRequestAPI(payload);
    return response.data;
  }
);
