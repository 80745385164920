import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
//import logo
import Logo from "../assets/images/pay-logo.png";
//Import Components
import VerticalLayout from "./VerticalLayouts";
import { Container } from "reactstrap";

const Sidebar = () => {
  const sidebarRef = useRef(null);
  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        // document.body.classList.remove('vertical-sidebar-enable');
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (document.documentElement.getAttribute("data-sidebar-size") === "sm-hover") {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover-active");
    } else if (document.documentElement.getAttribute("data-sidebar-size") === "sm-hover-active") {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  useEffect(() => {
    var windowSize = document.documentElement.clientWidth;
    const handleClickOutside = (event) => {
      if (
        document.body.classList.contains("vertical-sidebar-enable") &&
        windowSize <= 767 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        document.body.classList.remove("vertical-sidebar-enable");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu" ref={sidebarRef}>
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={Logo} alt="" height="60" />
            </span>
            <span className="logo-lg">
              <img src={Logo} alt="" height="60" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={Logo} alt="" height="60" />
            </span>
            <span className="logo-lg">
              <img src={Logo} alt="" height="60" />
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>

        <React.Fragment>
          <SimpleBar id="scrollbar" className="h-100">
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="navbar-nav" id="navbar-nav">
                <VerticalLayout />
              </ul>
            </Container>
          </SimpleBar>
          <div className="sidebar-background"></div>
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
