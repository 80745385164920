import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPayInOutCountAPI,
  getPayInRequestsAPI,
  getPayOutRequestsAPI,
  getSignedImageURLAPI,
  processPayInRequestAPI,
  processPayOutRequestAPI,
} from "../../helpers/apis";

export const getPayInRequests = createAsyncThunk("Payments/getPayInRequests", async (payload) => {
  const response = await getPayInRequestsAPI(payload);
  return response.data;
});

export const getPayInOutCount = createAsyncThunk("Payments/getPayInOutCount", async (payload) => {
  const response = await getPayInOutCountAPI(payload);
  return response.data;
});

export const getPayOutRequests = createAsyncThunk("Payments/getPayOutRequests", async (payload) => {
  const response = await getPayOutRequestsAPI(payload);
  return response.data;
});

export const getSignedImageURL = createAsyncThunk("Payments/getSignedImageURL", async (payload) => {
  const response = await getSignedImageURLAPI(payload);
  return response.data;
});

export const processPayInRequest = createAsyncThunk(
  "Payments/processPayInRequest",
  async (payload) => {
    const response = await processPayInRequestAPI(payload);
    return response.data;
  }
);

export const processPayOutRequest = createAsyncThunk(
  "Payments/processPayOutRequest",
  async (payload) => {
    console.log("payloadpayloadpayloadpayload", payload);
    const response = await processPayOutRequestAPI(payload.data, payload.type);
    return response.data;
  }
);
