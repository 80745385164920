import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postUserBank,
  getUserBanks as getUserBanksAPI,
  patchUserBanks as patchUserBanksAPI,
  deleteUserBanks as deleteUserBanksAPI,
  getUsersAssignedToBankAPI,
} from "../../helpers/apis";

export const createUserBanks = createAsyncThunk(
  "userBank/createUserBanks",
  async (payload) => {
    const response = await postUserBank(payload);
    return response;
  }
);

export const getUserBanks = createAsyncThunk(
  "userBank/getUserBanks",
  async (payload) => {
    const response = await getUserBanksAPI(payload);
    return response.data;
  }
);

export const getUsersAssignedToBank = createAsyncThunk(
  "userBank/getUsersAssignedToBank",
  async (payload) => {
    const response = await getUsersAssignedToBankAPI(payload);
    return response.data;
  }
);

export const deleteUserBanks = createAsyncThunk(
  "userBank/deleteUserBanks",
  async (payload) => {
    const response = await deleteUserBanksAPI(payload);
    return response;
  }
);

export const patchUserBanks = createAsyncThunk(
  "userBank/patchUserBanks",
  async (payload) => {
    const response = await patchUserBanksAPI(payload);
    return response;
  }
);
