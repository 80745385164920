import { createAsyncThunk } from "@reduxjs/toolkit";
import { 
  getBankList as getBankListAPI, 
  addBank as addBankAPI,
  createPaymentBank as createPaymentBankAPI,
  updatePaymentBank  as updatePaymentBankAPI,
  deletePaymentBank  as deletePaymentBankAPI,
  getPaymentBank 
} from "../../helpers/apis";

export const getBankList = createAsyncThunk("user/getBankList", async () => {
  const response = await getBankListAPI();
  return response.data;
});

export const addBank = createAsyncThunk("user/addBank", async (payload) => {
  const response = await addBankAPI(payload);
  return response.data;
});

export const getPaymentBankList = createAsyncThunk(
  "market/getPaymentBankList",
  async (payload) => {
    const response = await getPaymentBank(payload);
    return response.data;
  }
);

export const createPaymentBank = createAsyncThunk(
  "market/createPaymentBank",
  async (payload) => {
    const response = await createPaymentBankAPI(payload);
    return response.data;
  }
);

export const updatePaymentBank = createAsyncThunk(
  "market/updatePaymentBank",
  async ({ id, update }) => {
    const response = await updatePaymentBankAPI(id, update);
    return response.data;
  }
);

export const deletePaymentBank = createAsyncThunk(
  "market/deletePaymentBank",
  async (payload) => {
    const response = await deletePaymentBankAPI(payload);
    return response.data;
  }
);
