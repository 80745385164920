import { createSlice } from "@reduxjs/toolkit";
import {
  getPayInHistory,
  getPayInOutReport,
  getPayOutHistory,
  getManualOrderHistory,
  getTransactionHistory
} from "./actions";

const initialState = {
  payInOutReport: {
    data: [],
    total: 0,
  },
  payInHistory: {
    data: [],
    total: 0,
  },
  payOutHistory: {
    data: [],
    total: 0,
  },
  manualOrderHistory: {
    data: [],
    total: 0,
  },
  transactionHistory: {
    data: [],
    total: 0,
  },
};

const reportSlice = createSlice({
  name: "Reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPayInOutReport.fulfilled, (state, action) => {
        return {
          ...state,
          payInOutReport: {
            ...state.payInOutReport,
            data: action.payload.items,
            total: action.payload.totalRows,
          },
        };
      })
      .addCase(getPayInHistory.fulfilled, (state, action) => {
        return {
          ...state,
          payInHistory: {
            ...state.payInHistory,
            data: action.payload.items,
            total: action.payload.totalRows,
          },
        };
      })
      .addCase(getPayOutHistory.fulfilled, (state, action) => {
        return {
          ...state,
          payOutHistory: {
            ...state.payOutHistory,
            data: action.payload.items,
            total: action.payload.totalRows,
          },
        };
      })
      .addCase(getManualOrderHistory.fulfilled, (state, action) => {
        return {
          ...state,
          manualOrderHistory: {
            ...state.manualOrderHistory,
            data: action.payload.items,
            total: action.payload.totalRows,
          },
        };
      })
      .addCase(getTransactionHistory.fulfilled, (state, action) => {
        return {
          ...state,
          transactionHistory: {
            ...state.manualOrderHistory,
            data: action.payload.items,
            total: action.payload.totalRows,
          },
        };
      });
  },
});

export default reportSlice.reducer;
