import { createSlice } from "@reduxjs/toolkit";
import {
  addNewAdmin,
  assignAdminUsers,
  deleteAdminUsers,
  getAdminList,
  getAdmins,
  getAdminUsers,
  updateUsersModule,
} from "./action";
import { toast } from "react-toastify";

const initialState = {
  admins: [],
  adminList: [],
  adminCount: 0,
  adminUsers: { users: [], totalRows: 0 },
  error: "",
};

const adminsSlice = createSlice({
  name: "Admins",
  initialState,
  reducers: {
    setAdminsList: (state, action) => {
      state.admins = action.payload.items;
      state.adminCount = action.payload.total;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getAdmins.fulfilled, (state, action) => {
      state.admins = action.payload.items;
      state.adminCount = action.payload.total;
    });

    b.addCase(getAdminList.fulfilled, (state, action) => {
      state.adminList = action.payload;
    });

    b.addCase(assignAdminUsers.fulfilled, (state, action) => {
      if (action.payload?.status)
        toast.success("User assigned to admin successfully.");
    });

    b.addCase(getAdminUsers.fulfilled, (state, action) => {
      state.adminUsers.users = action.payload.users;
      state.adminUsers.totalRows = action.payload.total;
    });

    b.addCase(deleteAdminUsers.fulfilled, (state, action) => {
      if (action.payload?.status) toast.success("Removed users successfully.");
    });

    b.addCase(updateUsersModule.fulfilled, (state, action) => {
      if (action.payload?.status)
        toast.success("User modules updated successfully.");
    });
    b
      .addCase(addNewAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewAdmin.fulfilled, (state) => {
        state.loading = false;
        toast.success("Admin added successfully!", { autoClose: 3000 });
      })
      .addCase(addNewAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(`${action.error.message}`, {
          autoClose: 3000,
        });
      });
  },
});

export const { setAdminsList, setError } = adminsSlice.actions;

export default adminsSlice.reducer;
