import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

// Import Data
import navdata from "../LayoutMenuData";
import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";
import { ENUM_ROLE_TYPE } from "../../Components/constants/constants";
import { useState } from "react";
import { ENUM_ADMIN_TYPE } from "../../helpers/constants";

const VerticalLayout = (props) => {
  const [navDataList, setNavData] = useState(null);
  const path = props.router.location.pathname;
  const navData = navdata().props.children;

  const { type, modules } = useSelector((state) => ({
    type: state.User.type,
    modules: state.User.modules,
  }));

  const initMenu = () => {
    const pathName = process.env.PUBLIC_URL + path;
    const ul = document.getElementById("navbar-nav");
    const items = ul.getElementsByTagName("a");
    let itemsArray = [...items]; // converts NodeList to Array
    removeActivation(itemsArray);
    let matchingMenuItem = itemsArray.find((x) => {
      return x.pathname === pathName;
    });
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    initMenu();
  }, [path]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
        if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
        if (
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .classList.add("show");
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .previousElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }
  const removeActivation = (items) => {
    let actiItems = items.filter((x) => x.classList.contains("active"));

    actiItems.forEach((item) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  useEffect(() => {
    const navDataNew = navData.filter((obj) => {
      if (type === ENUM_ADMIN_TYPE.ADMIN) {
        return obj.id !== "settings";
      }

      if (type === ENUM_ADMIN_TYPE.SUPERADMIN) {
        return obj.id !== "banks" && obj.id !== "administration";
      }

      // if (type !== ENUM_ROLE_TYPE.SUPERADMIN) {
      //   return obj.id !== "settings";
      // }

      return true;
    });
    if (JSON.stringify(navDataNew) !== JSON.stringify(navDataList)) {
      setNavData(navDataNew);
    }
    initMenu();
  }, [navData]);

  return (
    <React.Fragment>
      {/* menu Items */}
      {(navDataList || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item["isHeader"] ? (
              <li className="menu-title">
                <span data-key="t-menu">{item.label} </span>
              </li>
            ) : item.subItems ? (
              type != ENUM_ROLE_TYPE.SUBADMIN ||
              (type == ENUM_ROLE_TYPE.SUBADMIN && modules?.includes(item.id)) ? (
                <li className="nav-item">
                  <Link
                    onClick={item.click}
                    className="nav-link menu-link"
                    to={item.link ? item.link : "/#"}
                    data-bs-toggle="collapse"
                  >
                    <i className={item.icon}></i>
                    <span data-key="t-apps">{item.label}</span>
                    {item.badgeName ? (
                      <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">
                        {item.badgeName}
                      </span>
                    ) : null}
                  </Link>
                  <Collapse className="menu-dropdown" isOpen={item.stateVariables} id={item.id}>
                    <ul className="nav nav-sm flex-column test">
                      {item.subItems &&
                        (item.subItems || []).map((subItem, key) => {
                          if (subItem.id == "general-setting" && type == ENUM_ROLE_TYPE.ADMIN)
                            return "";
                          if (
                            type != ENUM_ROLE_TYPE.SUBADMIN ||
                            (type == ENUM_ROLE_TYPE.SUBADMIN && modules?.includes(subItem.id))
                          ) {
                            return (
                              <React.Fragment key={key}>
                                {!subItem.isChildItem ? (
                                  <li className="nav-item">
                                    <Link
                                      to={subItem.link ? subItem.link : "/#"}
                                      className="nav-link"
                                      onClick={() =>
                                        document.body.classList.remove("vertical-sidebar-enable")
                                      }
                                    >
                                      {subItem.label}{" "}
                                      {subItem.badgeName ? (
                                        <span
                                          className={"badge badge-pill bg-" + subItem.badgeColor}
                                          data-key="t-new"
                                        >
                                          {subItem.badgeName}
                                        </span>
                                      ) : null}
                                    </Link>
                                  </li>
                                ) : (
                                  <li className="nav-item">
                                    <Link
                                      onClick={subItem.click}
                                      className="nav-link"
                                      to="/#"
                                      data-bs-toggle="collapse"
                                    >
                                      {subItem.label}
                                      {subItem.badgeName ? (
                                        <span
                                          className={"badge badge-pill bg-" + subItem.badgeColor}
                                          data-key="t-new"
                                        >
                                          {subItem.badgeName}
                                        </span>
                                      ) : null}
                                    </Link>
                                    <Collapse
                                      className="menu-dropdown"
                                      isOpen={subItem.stateVariables}
                                      id="sidebarEcommerce"
                                    >
                                      <ul className="nav nav-sm flex-column">
                                        {/* child subItms  */}
                                        {subItem.childItems &&
                                          (subItem.childItems || []).map((childItem, key) => (
                                            <React.Fragment key={key}>
                                              {!childItem.childItems ? (
                                                <li className="nav-item">
                                                  <Link
                                                    to={childItem.link ? childItem.link : "/#"}
                                                    className="nav-link"
                                                  >
                                                    {childItem.label}
                                                  </Link>
                                                </li>
                                              ) : (
                                                <li className="nav-item">
                                                  <Link
                                                    to="/#"
                                                    className="nav-link"
                                                    onClick={childItem.click}
                                                    data-bs-toggle="collapse"
                                                  >
                                                    {childItem.label}
                                                  </Link>
                                                  <Collapse
                                                    className="menu-dropdown"
                                                    isOpen={childItem.stateVariables}
                                                    id="sidebaremailTemplates"
                                                  >
                                                    <ul className="nav nav-sm flex-column">
                                                      {childItem.childItems.map(
                                                        (subChildItem, key) => (
                                                          <li className="nav-item" key={key}>
                                                            <Link
                                                              to={subChildItem.link}
                                                              className="nav-link"
                                                              data-key="t-basic-action"
                                                            >
                                                              {subChildItem.label}{" "}
                                                            </Link>
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  </Collapse>
                                                </li>
                                              )}
                                            </React.Fragment>
                                          ))}
                                      </ul>
                                    </Collapse>
                                  </li>
                                )}
                              </React.Fragment>
                            );
                          } else return "";
                        })}
                    </ul>
                  </Collapse>
                </li>
              ) : (
                ""
              )
            ) : type != ENUM_ROLE_TYPE.SUBADMIN ||
              (type == ENUM_ROLE_TYPE.SUBADMIN && modules?.includes(item.id)) ? (
              <li className="nav-item">
                <Link
                  className="nav-link menu-link"
                  to={item.link ? item.link : "/#"}
                  onClick={() => document.body.classList.remove("vertical-sidebar-enable")}
                >
                  <i className={item.icon}></i> <span>{item.label}</span>
                  {item.badgeName ? (
                    <span className={"badge badge-pill bg-" + item.badgeColor} data-key="t-new">
                      {item.badgeName}
                    </span>
                  ) : null}
                </Link>
              </li>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(VerticalLayout);
