import { createSlice } from "@reduxjs/toolkit";

import {
  getManualOrders,
  addManualOrder,
  processManualOrderRequest,
  declineAllManualOrderRequests,
  processImageRequest,
  getManualOrderCount,
  getUnmatchManualOrders,
} from "./actions";
import { toast } from "react-toastify";

const initialState = {
  manualOrders: [],
  totalRows: 0,
  manualOrderCount: 0,
  unmatchManualOrders: [],
};

const manualOrderSlice = createSlice({
  name: "ManualOrder",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getManualOrders.fulfilled, (state, action) => {
        state.manualOrders = action.payload.items;
        state.totalRows = action.payload.total;
      })
      .addCase(getManualOrders.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to fetch, Please try again"
        );
      });

    builder
      .addCase(getUnmatchManualOrders.fulfilled, (state, action) => {
        state.unmatchManualOrders = action.payload.items;
        state.totalRows = action.payload.total;
      })
      .addCase(getUnmatchManualOrders.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to fetch, Please try again"
        );
      });

    builder.addCase(getManualOrderCount.fulfilled, (state, action) => {
      state.manualOrderCount = action.payload?.total || 0;
    });

    builder
      .addCase(processManualOrderRequest.fulfilled, (state, action) => {
        toast.success(action.payload?.message || "Manual Order Processed");
      })
      .addCase(processManualOrderRequest.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to update manual order"
        );
      });
    builder
      .addCase(declineAllManualOrderRequests.fulfilled, (state, action) => {
        toast.success(action.payload?.message || "Manual Orders declined");
      })
      .addCase(declineAllManualOrderRequests.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to decline manual orders"
        );
      });
    builder
      .addCase(processImageRequest.fulfilled, (state, action) => {
        toast.success(action.payload?.message || "Manual Order Processed");
      })
      .addCase(processImageRequest.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to update manual order"
        );
      });
  },
});

export default manualOrderSlice.reducer;
