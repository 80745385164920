import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserProfile as getUserProfileAPI,
  enable2FA as enable2FAAPI,
  verify2FA as verify2FAAPI,
  disable2FA as disable2FAAPI,
  resetPassword as resetPasswordApi,
  updateUserPasswordAPI,
  getUserSettingsAPI,
  updateUserSettingsAPI,
  updatePercentageAPI,
} from "../../helpers/apis";
import { toast } from "react-toastify";

// 2FA
export const enable2FA = createAsyncThunk("user/enable2FA", async () => {
  const response = await enable2FAAPI();
  return response.data;
});
export const verify2FA = createAsyncThunk("user/verify2FA", async (code) => {
  const response = await verify2FAAPI(code);
  return response.data;
});

export const disable2FA = createAsyncThunk("user/disable2FA", (code) => {
  const response = disable2FAAPI(code);
  return response;
});

export const resetVerify2FA = createAsyncThunk("user/resetVerify2FA", () => {
  return true;
});

export const editProfile = createAsyncThunk("user/editProfile", (user) => {
  return true;
});

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async () => {
    const response = await getUserProfileAPI();
    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data) => {
    const response = await resetPasswordApi(data);
    return response?.data;
  }
);

export const updateUserPassword = createAsyncThunk(
  "Users/updateUserPassword",
  async (data) => {
    try {
      await updateUserPasswordAPI(data);
      toast.success("User Password Updated Successfully", { autoClose: 3000 });
      return true;
    } catch (error) {
      toast.error(error ?? "Failed to Update User Password", { autoClose: 3000 });
      return false;
    }
  }
);

export const getUserSettings = createAsyncThunk("user/getUserSettings", async (payload) => {
  const response = await getUserSettingsAPI(payload);
  return response.data;
});

export const updateUserSettings = createAsyncThunk("user/updateUserSettings", async (payload) => {
  const response = await updateUserSettingsAPI(payload);
  return response.data;
});
export const updatePercentage = createAsyncThunk("user/updatePercentage", async (payload) => {
  const response = await updatePercentageAPI(payload);
  return response.data;
});
