//constants
import { layoutModeTypes } from "../../Components/constants/layout";
import { createSlice } from "@reduxjs/toolkit";

import { changeLayoutMode, changeLoader } from "./actions";
const tempLayoutMode = localStorage.getItem("layoutModeType");
const initialState = {
  layoutModeType: tempLayoutMode ? tempLayoutMode : layoutModeTypes.DARKMODE,
  userDetailsModal: false,
  loader: false,
};

const Layout = createSlice({
  name: "Layout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeLoader.fulfilled, (state, action) => {
      state.loader = action.payload;
    });
    builder.addCase(changeLayoutMode.fulfilled, (state, action) => {
      state.layoutModeType = action.payload;
    });
  },
});
export default Layout.reducer;
