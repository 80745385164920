import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
// import GeneralSetting from "../pages/GeneralSettings";

const PayInList = lazy(() => import("../pages/Payment/PayIn"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

const BasicTwosVerify = lazy(() =>
  import("../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify")
);
const Basic404 = lazy(() => import("../pages/AuthenticationInner/Errors/Basic404"));
const Error500 = lazy(() => import("../pages/AuthenticationInner/Errors/Error500"));

//login
const Login = lazy(() => import("../pages/Auth/Login"));
const Logout = lazy(() => import("../pages/Auth/Logout"));
const Users = lazy(() => import("../pages/User"));
const UserProfile = lazy(() => import("../pages/User/Profile"));
const Banks = lazy(() => import("../pages/Bank/Bank"));
const Admin = lazy(() => import("../pages/Administration"));
const AdminUsers = lazy(() => import("../pages/Administration/AdminUsers"));
const PayInHistory = lazy(() => import("../pages/Reports/PaymentReports/PayInHistory"));
const PayOutHistory = lazy(() => import("../pages/Reports/PaymentReports/PayOutHistory"));
const MerchantPayout = lazy(() => import("../pages/Settings/MerchantPayout"));
const MerchantUsers = lazy(() => import("../pages/Settings/MerchantPayout/MerchantUsers"));
const PayInOutReport = lazy(() => import("../pages/Reports/PaymentReports/PayInOutReport"));
const BasicLockScreen = lazy(() => import("../pages/AuthenticationInner/LockScreen/BasicLockScr"));
const ManualOrder = lazy(() => import("../pages/ManualOrder/ManualOrder"));
const UnmatchManualOrder = lazy(() => import("../pages/ManualOrder/UnmatchManualOrder"));
const PayOutList = lazy(() => import("../pages/Payment/PayOut"));
const ManualOrderReport = lazy(() => import("../pages/Reports/ManualOrderHistory"));
const TransactionReport = lazy(() => import("../pages/Reports/TransactionHistory"));

const GeneralSetting = lazy(() => import("../pages/GeneralSettings"));
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },
  { path: "/users", component: <Users /> },

  { path: "/manual-order", component: <ManualOrder /> },
  { path: "/unmatch-manual-order", component: <UnmatchManualOrder /> },

  { path: "/banks/all-banks", component: <Banks /> },

  { path: "/payments/pay-in", component: <PayInList /> },
  { path: "/payments/pay-out", component: <PayOutList /> },

  { path: "/reports/pay-out-history", component: <PayOutHistory /> },
  { path: "/reports/pay-in-history", component: <PayInHistory /> },
  { path: "/reports/manual-order-history", component: <ManualOrderReport /> },
  { path: "/reports/transaction-history", component: <TransactionReport /> },

  // { path: "/banks/user-banks", component: <UserBank /> },
  { path: "/administration/admin", component: <Admin /> },
  { path: "/admin/:adminId/users", component: <AdminUsers /> },

  //reports
  { path: "/reports/pay-in-out-report", component: <PayInOutReport /> },

  //Settings
  { path: "/settings/payout-merchant", component: <MerchantPayout /> },
  {
    path: "/settings/payout-merchant/:merchantId/users",
    component: <MerchantUsers />,
  },

  //User Profile
  { path: "/users/profile", component: <UserProfile page="profile" /> },
  { path: "/users/setting", component: <UserProfile page="setting" /> },
  {
    path: "/users/change-password",
    component: <UserProfile page="change-password" />,
  },

  // { path: "/settings/payout-merchant", component: <PayoutMerchant /> },
  { path: "/settings/general-setting", component: <GeneralSetting /> },

  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  //AuthenticationInner pages
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/verify/twostep", component: <BasicTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
