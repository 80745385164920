import { toast } from "react-toastify";

const { createSlice } = require("@reduxjs/toolkit");
const {
  getMerchants,
  createMerchant,
  updateMerchant,
  deleteMerchant,
  getMerchantList,
  getMerchantUsers,
  deleteMerchantUsers,
  assignMerchantToUsers,
  getGeneralSettings,
  updateGeneralSettings,
} = require("./actions");

const initialState = {
  merchantPayout: { data: [], total: 0 },
  merchantPayoutList: [],
  merchantUsers: { data: [], total: 0 },
  settingMetaData: {},
};

const settingsSlice = createSlice({
  name: "Settings",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMerchants.fulfilled, (state, action) => {
      state.merchantPayout.data = action.payload.items;
      state.merchantPayout.total = action.payload.total;
    });

    builder.addCase(getMerchantList.fulfilled, (state, action) => {
      state.merchantPayoutList = action.payload;
    });

    builder.addCase(getMerchantUsers.fulfilled, (state, action) => {
      state.merchantUsers.data = action.payload.items;
      state.merchantUsers.total = action.payload.total;
    });

    builder
      .addCase(assignMerchantToUsers.fulfilled, (state, action) => {
        if (action.payload?.status)
          toast.success("Merchant user assign successfully.");
      })
      .addCase(assignMerchantToUsers.rejected, (state, action) => {
        toast.error(action?.error?.message || "Something went wrong");
      });

    builder
      .addCase(deleteMerchantUsers.fulfilled, (state, action) => {
        if (action.payload?.status)
          toast.success("Merchant user deleted successfully.");
      })
      .addCase(deleteMerchantUsers.rejected, (state, action) => {
        toast.error(action?.error?.message || "Something went wrong");
      });

    builder
      .addCase(createMerchant.fulfilled, (state, action) => {
        if (action.payload?.status)
          toast.success("Merchant created successfully.");
      })
      .addCase(createMerchant.rejected, (state, action) => {
        toast.error(action?.error?.message || "Something went wrong");
      });

    builder
      .addCase(updateMerchant.fulfilled, (state, action) => {
        if (action.payload?.status)
          toast.success("Merchant updated successfully.");
      })
      .addCase(updateMerchant.rejected, (state, action) => {
        toast.error(action?.error?.message || "Something went wrong");
      });

    builder
      .addCase(deleteMerchant.fulfilled, (state, action) => {
        if (action.payload?.status)
          toast.success("Merchant deleted successfully.");
      })
      .addCase(deleteMerchant.rejected, (state, action) => {
        toast.error(action?.error?.message || "Something went wrong");
      });

    builder
      .addCase(getGeneralSettings.fulfilled, (state, action) => {
        if (action.payload.name == "automaticLogoutTime") {
          state.logoutTime = action.payload.metaData?.logoutTime;
        }
        state.settingMetaData = action.payload;
      })
      .addCase(getGeneralSettings.rejected, (state, action) => {
        state.settingMetaData = {};
        toast.error("Error in getting setting data.");
      });

    builder.addCase(updateGeneralSettings.fulfilled, (state, action) => {
      toast.success("Setting updated Successfully.");
    });
  },
});

export default settingsSlice.reducer;
