import { createSlice } from "@reduxjs/toolkit";

import { addUser, getUserList, getUsers } from "./actions";
import { toast } from "react-toastify";

const initialState = {
  users: [],
  userList: [],
  totalRows: 0,
};

const userSlice = createSlice({
  name: "Users",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.users = action.payload.items;
        state.totalRows = action.payload.total;
      })
      .addCase(getUsers.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to fetch, Please try again"
        );
      })
      .addCase(addUser.fulfilled, (state, action) => {
        toast.success("User Created");
      })
      .addCase(addUser.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to create user"
        );
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList = action.payload;
      });
  },
});

export default userSlice.reducer;
