import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAdminApi, assignUserToAdminAPI, deleteAdminApi, deleteAdminUsersApi, getAdminListApi, getAdminsApi, getAdminUsersApi, updateAdminApi, updateUsersModuleApi } from "../../../helpers/apis";


export const assignAdminUsers = createAsyncThunk("user/assignAdminUsers", async (payload) => {
  const response = await assignUserToAdminAPI(payload);
  return response;
});

export const getAdmins = createAsyncThunk(
  "Admins/getAdmins",
  async (payload) => {
    const response = await getAdminsApi(payload);
    return response.data;
  }
);

export const getAdminList = createAsyncThunk(
  "Admins/getAdminList",
  async (payload) => {
    const response = await getAdminListApi(payload);
    return response.data;
  }
);

export const addNewAdmin = createAsyncThunk(
  "Admins/addNewAdmin",
  async (payload) => {
    if (!("create" in payload)) throw new Error("Invalid payload!");
    return await addAdminApi(payload.create);
  }
);

export const deleteAdmin = createAsyncThunk(
  "Admins/deleteAdmin",
  async (data) => {
    try {
      if (!("adminIds" in data)) throw new Error("Admin id not selected");
      await deleteAdminApi(data.adminIds);
      delete data.adminIds;
      return true;
    } catch (error) {
      toast.error("Failed to delete admin", { autoClose: 3000 });
      return false;
    }
  }
);

export const updateAdminDetail = createAsyncThunk(
  "Admins/updateAdminDetail",
  async (data) => {
    try {
      if (!("update" in data)) return;
      await updateAdminApi(data.update);
      delete data.update;
      toast.success("Admin Update Successfully", { autoClose: 3000 });
      return true;
    } catch (error) {
      toast.error("Failed to Update admin", { autoClose: 3000 });
      return false;
    }
  }
);

export const getAdminUsers = createAsyncThunk(
  "Admins/getAdminUsers",
  async (payload) => {
    const response = await getAdminUsersApi(payload);
    return response.data;
  }
);

export const updateUsersModule = createAsyncThunk(
  "Admins/updateUsersModule",
  async (payload) => {
    const response = await updateUsersModuleApi(payload);
    return response;
  }
);

export const deleteAdminUsers = createAsyncThunk(
  "Admins/deleteAdminUsers",
  async (payload) => {
    const response = await deleteAdminUsersApi(payload);
    return response.data;
  }
);
