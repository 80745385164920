import React, { useEffect, useLayoutEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import { toast } from "react-toastify";
import Route from "./Routes";
import { encrypt } from "./helpers/methods/encryptDecrypt";
import { getLoggedinUser, updateHeader } from "./helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { ClientJS } from "clientjs";
import { api } from "./config";
import { publicIpv4 } from "public-ip";
import { getPayInOutCount } from "./store/actions";
import socket from "./sockets/socket";
import { useNavigate } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = new ClientJS();
  const { user } = useSelector((state) => ({
    user: state.User.user,
  }));

  useLayoutEffect(() => {
    const geoData = localStorage.getItem("_GE_IED_");
    if (!geoData) {
      const browser = client.getBrowser();
      const browserVersion = client.getBrowserVersion();
      const fingerprint = client.getFingerprint();
      const isMobile = client.isMobile();

      publicIpv4({
        fallbackUrls: [api.IP_URL, api.IP2_URL],
      }).then(async (e) => {
        const data = {
          ip: e,
          browser,
          browserVersion,
          fingerprint,
          deviceType: isMobile ? "MOBILE" : "WEB",
        };
        const geoData = encrypt(data);

        await localStorage.setItem("_GE_IED_", geoData);
        updateHeader(data);
      });
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem("authUser")) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const isLoggedIn = getLoggedinUser();

  useEffect(() => {
    if (isLoggedIn) {
      socket.on("new-payin-request", async (data) => {
        toast.success("New payin request created.");
        dispatch(getPayInOutCount());
      });
      // socket.on("update-payIn", async (data) => {
      //   dispatch(getPayInOutCount());
      // });

      socket.on("utr-submitted", async (data) => {
        if (user?._id == data?.adminId) {
          toast.success("Utr Id submitted for payin.");
        }
      });
    }

    return () => {
      socket.off("new-payin-request");
      // socket.off("update-payIn");
      socket.off("utr-submitted");
    };
  }, []);

  useEffect(() => {
    const handleLogoutUser = (userId) => {
      if (userId == user?._id) {
        navigate("/logout");
      }
    };
    socket.on("logout-user", handleLogoutUser);

    return () => {
      socket.off("logout-user", handleLogoutUser);
    };
  }, [user._id]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
