import { api } from "../config";

//LOGIN
export const AUTH_LOGIN = "/v1/auth/login";
export const AUTH_VERIFY2FA = "/v1/auth/verify2fa";
export const AUTH_REFERSH_TOKEN = "/v1/auth/refresh-token";
export const GET_IP = process.env.REACT_APP_LOCATION_URL ?? "https://ipapi.co/json/";

export const GET_USER_PROFILE = "/v1/users/me/profile";
export const ENABLE_2FA = "/v1/users/me/enable2FA";
export const VERIFY_2FA = "/v1/users/me/verify2FA";
export const DISABLE_2FA = "/v1/users/me/disable2FA";
export const RESET_USER_PASSWORD = "/v1/users/me/password/reset";
export const UPDATE_USER_PASSWORD = "/v1/users/me/password/update";

//USER
export const GET_USERS = "/v1/users";
export const UPDATE_USER = "/v1/users";
export const GET_USER_LIST = "/v1/users/user-list";
export const ADD_NEW_USER = "/v1/users";
export const GET_USER = (id) => `/v1/users/${id}`;
export const GET_USER_SETTINGS = (id) => `/v1/users/${id}/settings`;
export const UPDATE_USER_SETTINGS = (id) => `/v1/users/${id}/settings`;
export const UPDATE_USER_PERCENTAGE = (id) => `/v1/users/percentage/${id}`;
export const SECURITY_KEY_URL = `/v1/users/security-key`;
export const USER_BALANCE_URL = (id) => `/v1/user-balance/${id}`;
export const TOKEN_URL = `/v1/users/token`;

//BANK
export const GET_BANK_LIST = "/v1/banks";
export const ADD_NEW_BANK = "/v1/banks";

export const GET_PAYMENT_BANK = "v1/banks/payment-bank";
export const SET_PAYMENT_BANK = "v1/banks/payment-bank";
export const PUT_PAYMENT_BANK = (id) => `v1/banks/payment-bank/${id}`;
export const DELETE_PAYMENT_BANK = "v1/banks/payment-bank";

//USER BANKS
export const POST_USER_BANKS = "v1/user-banks";
export const GET_USER_BANKS = "v1/user-banks";
export const GET_USER_ASSIGNED_BANKS = (id) => `v1/user-banks/assigned-users/${id}`;
export const PATCH_USER_BANKS = (id) => `v1/user-banks/${id}`;
export const DELETE_USER_BANKS = (id) => `v1/user-banks/${id}`;

//PAYMENTS
export const GET_PAY_IN_REQUESTS = "v1/payment/pay-in-requests";
export const GET_PAY_IN_OUT_COUNT = "v1/payment/pay-in-out-count";
export const GET_PAY_OUT_REQUESTS = "v1/payment/pay-out-requests";
export const POST_SIGNED_IMAGE_URL = "v1/payment/file-url";
export const PROCESS_PAYIN_REQUEST = (type) => `v1/payment/pay-in/process/${type}`;
export const PROCESS_PAYOUT_REQUEST = (type) => `v1/payment/pay-out/process/${type}`;
//ROLE
export const GET_ROLE_SETTINGS = "/v1/roles/settings";
export const GET_ROLE_LIST = "/v1/roles";
export const ADD_NEW_ROLE = "/v1/roles";
export const UPDATE_ROLE = "/v1/roles";
export const DELETE_ROLE = "/v1/roles";
export const GET_ROLE_DETAIL = (id) => `/v1/roles/${id}`;
export const GET_MODULE_LIST = "/v1/modules";

//ADMIN
export const GET_ADMINS = "/v1/admins";
export const GET_ADMIN_LIST = "/v1/admins/admin-list";
export const ADD_NEW_ADMIN = "/v1/admins";
export const DELETE_ADMIN = "/v1/admins";
export const UPDATE_ADMIN = "/v1/admins";
export const GET_ADMIN_USERS = (adminId) => `/v1/admins/${adminId}/admin-users`;
export const DELETE_ADMIN_USERS = (adminId) => `/v1/admins/${adminId}/admin-users/delete`;
export const RESET_ADMIN_PASSWORD = "/v1/admins/password/reset";

export const POST_ADMIN_USER = (adminId) => `/v1/admins/${adminId}/admin-users`;
export const PATCH_USER_MODULES = "/v1/admins/user-module";
export const PAYMENT_REPORT = `v1/reports/pay-in-out`;
export const PAYIN_HISTORY = `v1/reports/pay-in-history`;
export const PAYOUT_HISTORY = `v1/reports/pay-out-history`;
export const MANUAL_ORDER_HISTORY = `v1/reports/manual-order-history`;
export const TRANSACTION_HISTORY = `v1/reports/transactions`;
export const DOWNLOAD_PAYIN_OUT_REPORT = (type) => `v1/reports/pay-in-out/download/${type}`;
export const DOWNLOAD_PAYIN_REPORT = (type) => `v1/reports/pay-in-history/download/${type}`;
export const DOWNLOAD_PAYOUT_REPORT = (type) => `v1/reports/pay-out-history/download/${type}`;
export const DOWNLOAD_MANUAL_ORDER_REPORT = (type) =>
  `v1/reports/manual-order-history/download/${type}`;
export const DOWNLOAD_TRANSACTION_HISTORY_REPORT = (type) =>
  `v1/reports/transaction-history/download/${type}`;

export const DASHBOARD_PAY_IN_OUT_STAT = `v1/dashboard/pay-in-out/stat`;

export const PAY_IN_OUT_AVERAGE_TIME = `v1/dashboard/pay-in-out/average-time`;

//SETTINGS
export const MERCHANTS_PAYOUT = "v1/settings/merchants";
export const UPDATE_MERCHANTS_PAYOUT = (id) => `v1/settings/merchants/${id}`;
export const MERCHANT_PAYOUT_LIST = "v1/settings/merchants/merchant-list";
export const GET_MERCHANT_USERS = (id) => `v1/settings/merchants/${id}/merchant-user`;
export const POST_MERCHANT_USERS = (id) => `v1/settings/merchants/${id}/merchant-user`;
export const DELETE_MERCHANT_USERS = (id) => `v1/settings/merchants/${id}/merchant-user`;

export const GET_MANUAL_ORDERS = "/v1/manual-orders";
export const GET_UNMATCH_MANUAL_ORDERS = "/v1/manual-orders/unmatch";
export const GET_MANUAL_ORDERS_COUNT = "/v1/manual-orders/manual-order-count";
export const PROCESS_MANUAL_ORDER = "/v1/manual-orders";
export const DECLINE_MANUAL_ORDER = "/v1/manual-orders/decline-all";
export const IMAGE_MANUAL_ORDER = "/v1/manual-orders/payment-image";

export const GET_GENERAL_SETTINGS = (name) => `v1/general-settings/${name}`;
export const PATCH_GENERAL_SETTINGS = (name) => `v1/general-settings/${name}`;
